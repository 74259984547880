<template>
  <div ref="pageBox">
    <SearchBox ref="SearchBox">
      <el-form slot="form" :inline="true" label-width="80px" class="unitWidth" size="small">
        <el-form-item label="有效时间"
          ><el-date-picker
            v-model="time"
            type="daterange"
            align="right"
            unlink-panels
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="hanledatapicker"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="query.title" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <template slot="btns">
        <el-button size="small" @click="selrele()" type="primary">搜索</el-button>
      </template>
    </SearchBox>
    <LabelContent title="商业发布录入" ref="LabelContent">
      <!-- <div slot="btns">
        <el-button size="small" type="success" @click="btnAdd">新增</el-button>
      </div> -->
    </LabelContent>
    <div class="tableBox">
      <el-table
        stripe
        ref="multipleTable"
        :data="tableData"
        class="center"
        :height="tableHeight + 'px'"
        @sort-change="sortChange"
        :default-sort="{ prop: 'value', order: 'descending' }"
      >
        <el-table-column prop="title" width="150" label="标题" :sort-method="sortByDate" />
        <el-table-column
          type="String"
          prop="content"
          width="150"
          height="100"
          label="内容"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <p v-html="scope.row.content"></p>
          </template>
        </el-table-column>
        <el-table-column prop="creatorName" width="150" label="发布人名称" />
        <el-table-column width="200" prop="createTime" label="开始时间" />
        <el-table-column width="200" prop="endTime" label="截止时间" />
        <el-table-column prop="auditStatus.desc" width="200" label="上架状态" />
        <el-table-column label="操作" width="280">
          <template slot-scope="scope" class="flex">
            <el-button type="success" @click="shelves(scope.row)">上架</el-button>
            <el-button type="warning" @click="soldout(scope.row)">下架</el-button>
            <el-button type="primary" @click="tableDetail(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="paginationChange"
        @current-change="paginationChange"
        :current-page="testValue"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="testValue"
        layout="total, sizes, prev, pager, next, jumper"
        :total="testValue"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "Factory",
  data () {
    return {
      dialogFormVisible: false,
      batchDeleteArr: [],
      tableHeight: 0, //表格testValue高度
      testValue: 1,
      tableData: [],
      dialogTableVisible: false,
      formLabelWidth: "120px",
      time: "",
      query: {
        pageNum: 1,
        pageSize: 10,
        title: "", //标题
        auditStatus: "",
        timeStart: "",
        timeEnd: "",
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit("pick", [start, end])
            },
          },
          {
            text: "最近一个月",
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit("pick", [start, end])
            },
          },
          {
            text: "最近三个月",
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit("pick", [start, end])
            },
          },
        ],
      },
    }
  },
  mounted () {
    //设置表格高度
    this.$nextTick(() => {
      let page = this.$refs.pageBox.offsetHeight || 0 //卡片高度
      let SearchBox = this.$refs.SearchBox
        ? this.$refs.SearchBox.$el.offsetHeight
        : 0 //搜索框高度
      let LabelContent = this.$refs.LabelContent
        ? this.$refs.LabelContent.$el.offsetHeight
        : 0 //内容标题高度
      this.tableHeight = page - (SearchBox + LabelContent + 20 + 30) //padding -20,分页 -30
    })
    this.init()
  },
  methods: {
    //查询 zh
    selrele () {
      this.init()
    },
    //商业发布列表数据
    async init () {
      let result = await this.$http.request({
        url: "/hw/admin/articles/bw/page",
        method: "GET",
        params: this.query,
      })
      this.tableData = result.data.list
    },
    //跳转审核
    tableDetail (row) {
      this.$router.push({
        path: "/release_xiu/" + row.artId
      })
    },
    //删除当前数据
    deleteinfo (data) { },
    //删除弹框
    deleteApi (data) {
      const _this = this
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => { })
    },
    //上架
    async shelves (row) {
      const _this = this
      this.$confirm("确认上架吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.dialogFormVisible = true
        let result = await this.$http.request({
          url: "hw/admin/articles/business_wire/audit/" + row.artId,
          method: "POST",
          params: {
            auditStatus: "success"
          }
        })
        this.$message("上架成功！")
        this.init()
      })
    },
    //下架
    soldout (row) {
      const _this = this
      this.$confirm("确定下架吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.dialogFormVisible = true
        let result = await this.$http.request({
          url: "hw/admin/articles/business_wire/audit/" + row.artId,
          method: "POST",
          params: {
            auditStatus: "fail"
          }
        })
        this.$message("下架成功！")
        this.init()
      })
    },
    hanledatapicker () {
      this.query.timeStart = this.time[0]
      this.query.timeEnd = this.time[1]
    },
    //分页
    paginationChange (val) {
      console.log(val)
    },
    //表格行过滤
    formatterRow (row, column) {
    },
    //从后台获取数据,重新排序
    sortChange (val) {
      // 详细文档参考： https://element.eleme.cn/2.13/#/zh-CN/component/table
      console.log(val)
    },
    //自定义排序规则 sort-method
    sortByDate (a, b) {
      if (a < b) return -1
    },
    //跳转增加
    btnAdd (row) {
      this.$router.push({
        path: "/releas_detail"
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.batchdel {
  display: none;
}
.el-button--primary {
  width: 4.5vw;
  height: 2.5vw;
  line-height: 0px;
}
.stywid {
  margin-left: 1vw;
}
.unitWidth.el-form .el-form-item .el-form-item__content .el-select,
.unitWidth.el-form .el-form-item .el-form-item__content .el-date-editor,
.unitWidth.el-form .el-form-item .el-form-item__content .el-input,
.unitWidth.el-form .el-form-item .el-form-item__content .el-input-number {
  width: 25vw;
}
</style>
